<template>
  <el-container>
    <el-main v-if="flag">
      <div class="messageBoxTop">
        <el-card class="box-card">
          <h4>剩余短信条数</h4>
          <div>
            <span>{{ msgConfig.num }}</span>条
          </div>
          <div class="btn">
            <el-button size="mini" type="primary" @click="RechargeDialog">充值</el-button>
          </div>
        </el-card>
        <el-card class="box-card secondCard">
          <h4>今天已发</h4>
          <div>
            <span>{{ msgConfig.today }}</span>条
          </div>
        </el-card>
        <el-card class="box-card">
          <h4>总计发送</h4>
          <div>
            <span>{{ msgConfig.total }}</span>条
          </div>
          <div class="btn">
            <el-button size="mini" type="primary" @click="$router.push('/finance/rechargeConsumptionRecord')">充值发送记录
            </el-button>
          </div>
        </el-card>
      </div>
      <div class="notice">
        <h3>商家通知</h3>
        <hr />
        <div class="itemBox">
          <p>通知人手机号：</p>
          <div class="right">
            <el-input v-model="infoConfig.store.mobile" :rows="3" type="textarea"></el-input>
          </div>
        </div>
        <div class="tips">
          订单生成后以短信的方式商家通知，如果不填写则不通知(11位手机号，多个请以英文逗号隔开)
        </div>
        <div class="itemBox">
          <p>适用平台：</p>
          <div class="right">
            <div class="switchBox" :style="{
              border: infoConfig.store.platform == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.platform == 0 ? '' : '#eaf2ff'
            }">
              <div>总平台</div>
              <el-switch v-model="infoConfig.store.platform" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.store.wx_miniprogram == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.wx_miniprogram == 0 ? '' : '#eaf2ff'
            }">
              <div>微信小程序</div>
              <el-switch v-model="infoConfig.store.wx_miniprogram" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.store.ali_miniprogram == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.ali_miniprogram == 0 ? '' : '#eaf2ff'
            }">
              <div>支付宝小程序</div>
              <el-switch v-model="infoConfig.store.ali_miniprogram" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.store.baidu_miniprogram == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.baidu_miniprogram == 0 ? '' : '#eaf2ff'
            }">
              <div>百度小程序</div>
              <el-switch v-model="infoConfig.store.baidu_miniprogram" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.store.quick_app == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.quick_app == 0 ? '' : '#eaf2ff'
            }">
              <div>快应用联盟</div>
              <el-switch v-model="infoConfig.store.quick_app" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <p>订单通知：</p>
          <div class="right">
            <div class="switchBox" :style="{
              border: infoConfig.store.order_pay == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.order_pay == 0 ? '' : '#eaf2ff'
            }">
              <div>订单付款通知</div>
              <el-switch v-model="infoConfig.store.order_pay" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.store.order_finish == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.order_finish == 0 ? '' : '#eaf2ff'
            }">
              <div>订单确认收货通知</div>
              <el-switch v-model="infoConfig.store.order_finish" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.store.order_refund == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.order_refund == 0 ? '' : '#eaf2ff'
            }">
              <div>维权订单通知</div>
              <el-switch v-model="infoConfig.store.order_refund" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <p>续费通知：</p>
          <div class="right">
            <div class="switchBox" :style="{
              border: infoConfig.store.renew == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.renew == 0 ? '' : '#eaf2ff'
            }">
              <div>店铺续费通知</div>
              <el-switch v-model="infoConfig.store.renew" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.store.news_renew == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.store.news_renew == 0 ? '' : '#eaf2ff'
            }">
              <div>短信续费通知</div>
              <el-switch v-model="infoConfig.store.news_renew" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
          </div>
        </div>
      </div>
      <div class="notice">
        <h3>用户通知</h3>
        <hr />
        <div class="itemBox">
          <p>短信签名：</p>
          <div class="right">
            <el-input v-model="infoConfig.users.sign"></el-input>
            <!-- <p>验证</p> -->
            <!-- 定位 -->
            <p @click="lookexamplesMethond(16)">查看配置示例</p>
          </div>
        </div>
        <div class="tips">请查看示例配置短信签名</div>
        <div class="itemBox">
          <p>适用平台：</p>
          <div class="right">
            <div class="switchBox" :style="{
              border: infoConfig.users.platform == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.platform == 0 ? '' : '#eaf2ff'
            }">
              <div>总平台</div>
              <el-switch v-model="infoConfig.users.platform" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.users.wx_miniprogram == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.wx_miniprogram == 0 ? '' : '#eaf2ff'
            }">
              <div>微信小程序</div>
              <el-switch v-model="infoConfig.users.wx_miniprogram" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.users.ali_miniprogram == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.ali_miniprogram == 0 ? '' : '#eaf2ff'
            }">
              <div>支付宝小程序</div>
              <el-switch v-model="infoConfig.users.ali_miniprogram" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.users.baidu_miniprogram == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.baidu_miniprogram == 0 ? '' : '#eaf2ff'
            }">
              <div>百度小程序</div>
              <el-switch v-model="infoConfig.users.baidu_miniprogram" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.users.quickapp == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.quickapp == 0 ? '' : '#eaf2ff'
            }">
              <div>快应用联盟</div>
              <el-switch v-model="infoConfig.users.quickapp" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <p>登录验证：</p>
          <div class="right">
            <div class="switchBox" :style="{
              border: infoConfig.users.login == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.login == 0 ? '' : '#eaf2ff'
            }">
              <div>登录验证码</div>
              <el-switch v-model="infoConfig.users.login" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="tips1">仅限于快应用联盟(华为除外)，其余平台走授权登录</div>
          </div>
        </div>
        <div class="itemBox">
          <p>订单通知：</p>
          <div class="right">
            <div class="switchBox" :style="{
              border: infoConfig.users.order_submit == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.order_submit == 0 ? '' : '#eaf2ff'
            }">
              <div>订单提交成功通知</div>
              <el-switch v-model="infoConfig.users.order_submit" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.users.order_cancle == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.order_cancle == 0 ? '' : '#eaf2ff'
            }">
              <div>订单取消通知</div>
              <el-switch v-model="infoConfig.users.order_cancle" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.users.order_pay == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.order_pay == 0 ? '' : '#eaf2ff'
            }">
              <div>订单支付成功通知</div>
              <el-switch v-model="infoConfig.users.order_pay" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.users.order_send == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.order_send == 0 ? '' : '#eaf2ff'
            }">
              <div>订单发货通知</div>
              <el-switch v-model="infoConfig.users.order_send" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.users.order_finish == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.order_finish == 0 ? '' : '#eaf2ff'
            }">
              <div>订单确认收货通知</div>
              <el-switch v-model="infoConfig.users.order_finish" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <p>退货通知：</p>
          <div class="right">
            <div class="switchBox" :style="{
              border: infoConfig.users.order_refund == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.order_refund == 0 ? '' : '#eaf2ff'
            }">
              <div>退款申请通知</div>
              <el-switch v-model="infoConfig.users.order_refund" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.users.order_refund_progress == 0
                  ? '1px solid #ccc'
                  : '1px solid #588aff',
              background: infoConfig.users.order_refund_progress == 0 ? '' : '#eaf2ff'
            }">
              <div>退款进度通知</div>
              <el-switch v-model="infoConfig.users.order_refund_progress" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.users.refund_cg == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.refund_cg == 0 ? '' : '#eaf2ff'
            }">
              <div>退款成功通知</div>
              <el-switch v-model="infoConfig.users.refund_cg" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.users.refund_reject == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.refund_reject == 0 ? '' : '#eaf2ff'
            }">
              <div>退款申请驳回通知</div>
              <el-switch v-model="infoConfig.users.refund_reject" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
          </div>
        </div>
        <div class="itemBox">
          <p>支付通知：</p>
          <div class="right">
            <div class="switchBox" :style="{
              border: infoConfig.users.recharge_cg == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.recharge_cg == 0 ? '' : '#eaf2ff'
            }">
              <div>充值成功通知</div>
              <el-switch v-model="infoConfig.users.recharge_cg" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.users.recharge_tk == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.recharge_tk == 0 ? '' : '#eaf2ff'
            }">
              <div>充值退款通知</div>
              <el-switch v-model="infoConfig.users.recharge_tk" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border: infoConfig.users.withdrawal == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.withdrawal == 0 ? '' : '#eaf2ff'
            }">
              <div>提现提交通知</div>
              <el-switch v-model="infoConfig.users.withdrawal" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.users.withdrawal_success == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.withdrawal_success == 0 ? '' : '#eaf2ff'
            }">
              <div>提现成功通知</div>
              <el-switch v-model="infoConfig.users.withdrawal_success" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
            <div class="switchBox" :style="{
              border:
                infoConfig.users.withdrawal_fail == 0 ? '1px solid #ccc' : '1px solid #588aff',
              background: infoConfig.users.withdrawal_fail == 0 ? '' : '#eaf2ff'
            }">
              <div>提现失败通知</div>
              <el-switch v-model="infoConfig.users.withdrawal_fail" :active-value="1" :inactive-value="0">
              </el-switch>
            </div>
          </div>
        </div>
        <!-- <div class="itemBox">
          <p></p>
          <div class="right">
            <el-button type="primary" @click="preservation">保存</el-button>
          </div>
        </div> -->
        <Preservation @preservation="preservation"></Preservation>
      </div>
      <el-dialog title="充值" :visible.sync="RechargeFlag" width="52%">
        <el-form ref="form" label-width="120px">
          <el-form-item label="店铺名称：">{{ shopInfo.name }} </el-form-item>
          <el-form-item label="店铺可用余额：">{{ shopInfo.credit
          }}<el-button style="margin-left: 20px" type="text" @click="toRecharge">去充值</el-button>
          </el-form-item>
          <el-form-item label="充值短信数量：">
            <el-input style="width: 400px" v-model="rechargeMsgNum" type="number" min="500"></el-input>
            <span style="margin-left: 20px; color: red" v-show="rechargeMsgNum < 500">单次最少充值500条</span>
          </el-form-item>
          <el-form-item label="扣除金额：">{{ (rechargeMsgNum * 0.1).toFixed(2) }}</el-form-item>
          <el-form-item label="短信数量：">充值后短信：{{ Number(msgConfig.num) + Number(rechargeMsgNum) }}条</el-form-item>
          <el-form-item label="备注：">每条短信0.1元</el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="RechargeFlag = false">取消充值</el-button>
          <el-button type="primary" @click="sureRecharge">确认充值</el-button>
        </span>
      </el-dialog>
      <LookExamples ref="LookExamples" v-show="lookexamples" @hidden="hiddenexamples"></LookExamples>
    </el-main>
  </el-container>
</template>

<script>
import Preservation from '@/components/preservation'
import LookExamples from '@/components/previewImg'
export default {
  components: {
    Preservation,
    LookExamples
  },
  data () {
    return {
      RechargeFlag: false,
      isHave: false,
      infoConfig: {},
      id: -1,
      msgConfig: {},
      flag: false,
      rechargeMsgNum: 500,
      lookexamples: false
    }
  },
  computed: {
    // 用户的店铺信息
    shopInfo () {
      return this.$store.getters.shopInfo
    }
  },
  created () {
    console.log(this.shopInfo)
    this.getMsg()
    this.getConfig()
  },
  methods: {
    lookexamplesMethond (index) {
      this.$refs.LookExamples.getImgList(index)
      this.lookexamples = true
    },
    hiddenexamples () {
      this.lookexamples = false
    },
    // 跳转充值
    toRecharge () {
      this.$router.push('/finance/rechargeConsumptionRecord')
    },
    // 短信充值
    sureRecharge () {
      let that = this
      this.$axios
        .post(this.$api.set.ShopSmsOrder, {
          price: (this.rechargeMsgNum * 0.1).toFixed(2)
        })
        .then((res) => {
          if (res.code == 0) {
            that.RechargeFlag = false
            that.$message.success(res.msg)
            that.$store.dispatch('user/getShopInfo');
            that.getMsg()
          } else {
            that.$message.warning(res.msg)
          }
        })
    },
    // 打开充值弹框
    RechargeDialog () {
      this.RechargeFlag = true
    },
    // 获取短信数量
    getMsg () {
      let that = this
      this.$axios.post(this.$api.set.ShopSms, {}).then((res) => {
        if (res.code == 0) {
          that.msgConfig = res.result
        }
      })
    },
    // 获取配置
    getConfig () {
      let that = this
      this.$axios
        .post(this.$api.set.Settinginfo, {
          type: 5
        })
        .then((res) => {
          if (res.code === 0) {
            if (res.result.info == '') {
              that.infoConfig = {
                store: {
                  ali_miniprogram: 0,
                  baidu_miniprogram: 0,
                  mobile: null,
                  news_renew: 0,
                  order_finish: 0,
                  order_pay: 0,
                  order_refund: 0,
                  platform: 0,
                  quick_app: 0,
                  renew: 0,
                  wx_miniprogram: 0
                },
                users: {
                  ali_miniprogram: 0,
                  baidu_miniprogram: 0,
                  login: 0,
                  order_cancle: 0,
                  order_finish: 0,
                  order_pay: 0,
                  order_refund: 0,
                  order_refund_progress: 0,
                  order_send: 0,
                  order_submit: 0,
                  platform: 0,
                  quickapp: 0,
                  recharge_cg: 0,
                  recharge_tk: 0,
                  refund_cg: 0,
                  refund_reject: 0,
                  sign: null,
                  withdrawal: 0,
                  withdrawal_fail: 0,
                  withdrawal_success: 0,
                  wx_miniprogram: 0
                }
              }
              that.flag = true
              return
            }
            if (res.result.info.id !== undefined) {
              that.isHave = true
            }
            that.id = res.result.info.id
            that.infoConfig = res.result.info.sets
          }
          that.flag = true
        })
    },
    // 添加 修改设置
    preservation () {
      let that = this
      let url = ''
      let obj = {}
      if (this.isHave) {
        url = this.$api.set.Settingedit
        obj = {
          id: that.id,
          type: 5,
          sets: that.infoConfig
        }
      } else {
        url = this.$api.set.Settingadd
        obj = {
          type: 5,
          sets: that.infoConfig
        }
      }
      this.$axios.post(url, obj).then((res) => {
        if (res.code == 0) {
          that.$message({
            message: '保存成功',
            type: 'success'
          })
          that.isHave = true
          return
        }
        that.$message({
          message: '保存失败',
          type: 'error'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-container {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 10px 10px 20px 0;

  .el-main {
    &::-webkit-scrollbar {
      width: 0px;
      height: 5px;
      /**/
    }
  }
}

.messageBoxTop {
  display: flex;

  .el-card {
    width: 320px;
    height: 150px;
    margin-right: 20px;

    & /deep/ .el-card__body {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .btn {
        align-self: flex-end;
      }
    }
  }

  .secondCard {
    & /deep/ .el-card__body {
      height: 67% !important;
    }
  }
}

.notice {
  font-size: 14px;

  h3 {
    margin: 25px 0 15px 0;
  }

  hr {
    margin-bottom: 25px;
  }

  .itemBox {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &>p {
      width: 120px;
      text-align: right;
      margin-right: 20px;
      flex-shrink: 0;
    }

    .right {
      display: flex;
      align-items: center;

      .el-textarea {
        width: 500px;
      }

      .el-input {
        width: 500px;
      }

      p {
        margin-left: 20px;
        flex-shrink: 0;
        cursor: pointer;
        color: #409eff;
      }

      .switchBox {
        width: 200px;
        height: 80px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        border-radius: 10px;
        margin-right: 20px;
      }

      .tips1 {
        margin: 0;
        font-size: 12px;
        color: #bebebe;
      }
    }
  }

  .tips {
    margin: 0 0 20px 140px;
    font-size: 12px;
    color: #bebebe;
  }
}
</style>
